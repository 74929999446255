.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}

.avatar {
  position: relative;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:last-child) {
  margin-left: -20px;
}

.otherLeader.avatar:not(:last-child) {
  margin-left: -50px;
}

.avatar img {
  width: 100%;
  display: block;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 282px;
}

/* picked date align */
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
}

.rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 240px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
}

/* ok button style */
.rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #3498ff;
  width: 100px;
}

/* range style */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}

.rdrStaticRange {
  font-family: Montserrat;
  font-weight: 500;
}

.rdrCalendarWrapper {
  font-family: Montserrat;
  font-weight: 600;
}

.rdrInputRanges {
  display: none;
}

.rdrInRange {
  background-color: #7c69ec;
}

.rdrStartEdge[style] {
  background-color: #7c69ec !important;
  color: #7c69ec !important;
}

.rdrEndEdge[style] {
  background-color: #7c69ec !important;
  color: #7c69ec !important;
}

.rdrDayNumber {
  font-family: Montserrat;
}

.rdrStaticRangeSelected {
  color: #7c69ec !important;
}

.rdrDayStartPreview[style] {
  color: #7c69ec !important;
}

.rdrDayEndPreview[style] {
  color: #7c69ec !important;
}

.rdrDayInPreview[style] {
  color: #7c69ec !important;
}

.rdrDateDisplayItemActive {
  border-color: #7c69ec;
}

.trend-chart div.apexcharts-canvas {
  left: 0%;
}

.apexcharts-tooltip-text-y-label {
  display: none !important;
}
