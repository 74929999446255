div.DraftEditor-root {
  border: 1px solid #E7E7E7;
  min-height: 75px;
  overflow-y: scroll;
  resize: vertical;
  padding: 8px;
  background-color: #FFFFFF;
  border-radius: 4px;
}

.assessment div.DraftEditor-root {
  border: 1px solid #E7E7E7;
  height: 150px;
  overflow-y: auto;
  resize: vertical;
  padding: 8px;
  background-color: #FFFFFF;
}

.dashboard div.DraftEditor-root {
  border: 1px solid #E7E7E7;
  height: 75px;
  overflow-y: scroll;
  padding: 8px;
  resize: none;
  background-color: #FFFFFF;
}

.dashboard-autocomplete div.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-style: none;
}

.assessment div.DraftEditor-editorContainer {
  height: auto;
}

.assessment ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.assessment ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
}
.Leaderboard ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.Leaderboard ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
}

div.DraftEditor-root:hover,
:focus {
  border-color: #576bea;
}

.dashboard div.DraftEditor-root:hover,
:focus {
  border-color:  #576bea;
}

div.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-weight: 500;
  font-size: 14px;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}

div.public-DraftStyleDefault-ltr {
  color: #272727;
}

div.public-DraftStyleDefault-ltr {
  padding: 0px;
  font-weight: 500;
  font-family: Montserrat;
  line-height: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

label.rdw-link-modal-target-option {
  display: none;
}

@media screen and (max-width: 600px) {
  div.public-DraftStyleDefault-ltr {
    padding: 0.75px 5px 0.75px 5px;
    font-size: 10px;
  }
}

div.public-DraftStyleDefault-block {
  margin: 0px;
}

div.public-DraftEditorPlaceholder-inner {
  padding: 0px;
  font-weight: 500;
  font-family: Montserrat;
  line-height: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media screen and (max-width: 600px) {
  div.public-DraftEditorPlaceholder-inner {
    font-size: 10px;
    padding: 2px 5px 0.75px 6px;
  }
}

.assessment div.public-DraftStyleDefault-ltr {
  line-height: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.assessment div.public-DraftEditorPlaceholder-inner {
  line-height: 20px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

#connect-with-us .MuiInput-underline:before {
  border-bottom: 1px solid #DEDEDE;
}

#connect-with-us .MuiInput-underline:after {
  border-bottom: 1px solid #576BEA;
}

ul.public-DraftStyleDefault-ul {
  margin: 0px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 25px;
}

#workspace .MuiOutlinedInput-input {
  padding: 11px 14px;
}

#workspace .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: inherit;
  padding: 0px;
}

#workspace .MuiFormControl-root {
  height: inherit;
}

#workspace .MuiInputBase-root {
  height: inherit;
}

#workspace div.DraftEditor-root:hover,
:focus {
  border-color:  #272727;
}