.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.request-now-button-coachmark {
  left: 540px !important;
  top: 10px;
  background-color: #FFDA7C;
  border: #DFC774; 
  color: #272727; 
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 5px; 
}

.request-now-button-coachmark .shepherd-arrow:before {
  background-color: #FFDA7C !important;
  border-color: #DFC774 !important;
}

.shared-feedback-coachmark {
  left: 240px !important;
  top: 10px;
  background-color: #FFDA7C;
  border: #DFC774; 
  color: #272727; 
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px; 
}

.shared-feedback-coachmark .shepherd-arrow:before {
  background-color: #FFDA7C !important;
  border-color: #DFC774 !important;
}

.request-clients-coachmark {
  top: 50px !important;
  max-width: 600px;
  background-color: #FFDA7C;
  color: #272727;
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.25);
  padding: 2px;
  border-radius: 8px; 
}

.share-nugget-button-coachmark {
  left: 1200px !important;
  top: 330px !important;
  max-width: fit-content;
  background-color: #FFDA7C;
  color: #272727;
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.25);
  padding: 2px;
  border-radius: 8px; 
}

.my-teams-coachmark {
  left: 240px !important;
  top: 10px;
  background-color: #FFDA7C;
  border: #DFC774; 
  color: #272727; 
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px; 
  max-width: 480px; 
}

.share-nugget-button-coachmark .shepherd-arrow:before {
  background-color: #FFDA7C !important;
  border-color: #DFC774 !important;
}

.request-clients-coachmark .shepherd-arrow:before {
  background-color: #FFDA7C !important;
  border-color: #DFC774 !important;
}

.my-teams-coachmark .shepherd-arrow:before {
  background-color: #FFDA7C !important;
  border-color: #DFC774 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .custom-class-sm .shepherd-text {
    font-size: 10px; /* Adjust the font size as needed */
    text-align: center;
  }
  
  .shepherd-element  {
      max-width: 300px !important;
  }
}


