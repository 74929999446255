@font-face {
    font-family: 'EuclideCircularARegular';
    src: url("/src/modules/onboarding/landing_page/extra_fonts/EuclidCircularARegular.ttf") format('truetype');
}

@font-face {
    font-family: 'EuclideCircularAMedium';
    src: url("/src/modules/onboarding/landing_page/extra_fonts/EuclidCircularAMedium.ttf") format('truetype') ;
}

@font-face {
    font-family: 'EuclideCircularASemiBold';
    src: url("/src/modules/onboarding/landing_page/extra_fonts/EuclidCircularASemiBold.ttf") format('truetype');
}

@font-face {
    font-family: 'EuclideCircularABold';
    src: url("/src/modules/onboarding/landing_page/extra_fonts/EuclidCircularABold.ttf") format('truetype');
}


